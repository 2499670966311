<template>
  <el-dialog
    class="r-modal-window"
    title="Создать новое ТС"
    :visible="isVisible"
    :before-close="close"
    width="480px"
    top="50px"
    destroy-on-close
    :close-on-click-modal="false"
  >
    <r-block
      v-loading="loading"
      no-padding
    >
      <r-block
        no-padding
        col
      >
        <r-button
          type="primary"
          :disabled="saveDisabled"
          @click="create"
        >
          Сохранить ТС
        </r-button>
        <r-button
          simple
          @click="close"
        >
          Выйти без сохранения
        </r-button>
      </r-block>
      <attributes-list
        :model="model"
        :related="related"
        :source="source"
        is-editing
      />
    </r-block>
  </el-dialog>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import { notifyFactory } from '@/utils'
import { vehicleModel } from '../../configs'

export default {
  components: { attributesList },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      model: vehicleModel,
      source: {
        reg_number: null,
        driver_full_name: null,
        driver_phone: null,
        model_id: null,
        vehicle_type_id: null,
        main_contractor_id: null
      }
    }
  },
  computed: {
    related() {
      return this.$store.state.unload.related
    },
    saveDisabled() {
      return !this.source?.reg_number
    }
  },
  methods: {
    async create() {
      this.loading = true

      try {
        const data = {}

        this.model.filter(m => this.source[m.model]).forEach(m => {
          data[m.model] = this.source[m.model]
        })

        await this.$store.dispatch('POST_REQUEST', {
          url: 'vehicle?format=cargo',
          data
        })

        this.$notify(
          notifyFactory(
            'success',
            'Новое транспортное средство успешно создано!'
          )
        )
        this.$emit('update-vehicles')
        this.close()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
